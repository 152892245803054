@import "../../style/variables.scss";

ul.menu {
  list-style: none;
  padding: 4px 4px 0 4px;
  margin: 0 0 4px 0;

  li {
    width: 200px;
    padding: 4px;
    cursor: pointer;
    background-color: map-get($custom_colors, "gray3");
    margin: 3px 0;

    &:hover {
      margin-left: 10px;
    }
  }
}