.event-holder {
  width: 250px;
  overflow: hidden;

  .header {
    padding: 3px;
    font-weight: bold;
    border-bottom: 1px solid #cccccc;
    margin: 0 0 4px 0;
  }
}

.event-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 2px;

  &:hover {
    background-color: #efefef;
  }

  .event-paid {
    padding: 3px;
    width: 1.5em;
    text-align: right;
  }
}