.calendar {
  width: 7 * 31px;
  user-select: none;

  .calendar-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .calendar-cell {
      flex-basis: (100% / 7);
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
      padding: 3px;
      cursor: pointer;
      border: 2px solid transparent;

      &.selected {
        border: 2px solid #cccccc;
      }

      &.highlight {
        text-decoration: underline;
      }

      &.today {
        font-weight: bold;
        color: blue;
      }
    }

    .calendar-cell.placeholder {
      cursor: auto;
    }
  }

  .calendar-row.calendar-header {
    .calendar-cell {
      cursor: auto;
    }
  }

  .calendar-row:not(.calendar-header) {
    .calendar-cell:not(.placeholder):hover {
      background-color: #cccccc;
    }
  }

  .calendar-header {
    font-weight: bold;
  }

  .calendar-navi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cccccc;

    .control {
      flex-basis: (100% / 7);
      cursor: pointer;
    }

    .current-month-year {
      flex-basis: (100% / 7) * 5;
    }

    .control, .current-month-year {
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
      padding: 3px;
      font-weight: bold;
    }
  }
}