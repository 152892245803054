@import '~bootstrap/scss/bootstrap.scss';
@import './style/variables.scss';

html, body, #root {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

.page-item {
  cursor: pointer;
}



@each $name, $color in $custom_colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

.flex-1 {
  flex: 1 1;
}

.react-datepicker-wrapper {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}