$custom_colors: (
  gray1: #5C7080,
  gray2: #738694,
  gray3: #8A9BA8,
  gray4: #A7B6C2,
  gray5: #BFCCD6,
  lightgray1: #CED9E0,
  lightgray2: #D8E1E8,
  lightgray3: #E1E8ED,
  lightgray4: #EBF1F5,
  lightgray5: #F5F8FA
);